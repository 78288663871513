<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header consultation_head-filter">
                <div>
                    <div class="search me-0">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Consultation" v-model="form.keyword" @input="handleInput" @keydown.enter="getConsultationList()">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getConsultationList"></CustomFilter>
                    </span>
                    <button class="comman_brdr_btn consexport-btn" :disabled="this.selectedItems.length == 0"  @click="exportconsults()"> Export </button>
                </div>
                <div>
                    <button class="comman_btn" :class="{ 'add-prescription-disabled': !isUserDocORNurse() }"  v-if="$route.params.patient_id"> 
						<router-link target="_blank" :to="{ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: 'consultation' } }">Select Template</router-link> 
					</button>
                    <button class="comman_btn" :class="{ 'add-prescription-disabled': !isUserDocORNurse() }" v-if="$route.params.patient_id"> 
						<router-link target="_blank" :to="{ name: 'PatientEChartConsultAdd', params: { patient_id: this.$route.params.patient_id } }">Add Consultation</router-link>  
					</button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset cursor-auto"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'referral_date' }" @click="changeSort('referral_date')">Referral Date <img src="/images/down-arrow-new.svg" class="img-fluid " :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'patient_name' }" @click="changeSort('patient_name')">Demographic <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'specialist_type' }" @click="changeSort('specialist_type')">Service <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'specialist_name' }" @click="changeSort('specialist_name')">Specialist <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'status' }" @click="changeSort('status')">Latest Status <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" class="hover_unset cursor-auto"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.consultationsList" :key="index" :class="{ 'active': (selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="selectedItems.includes(item.id)" @change="checkSelect(item.id)"></td>
                            <td>{{ item.referral_date }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td>{{ item.specialist_type }}</td>
                            <td>{{ item.specialist_name }}</td>
                            <td>{{ item.status }}</td>
                            <td><span class="popup_link" @click="viewlogs(item.id)">View Logs</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-viewlogs-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeViewLogs"></button>
                    <div class="box">
						<h2 class="popup_title">Tracing History</h2>
						<div class="table-responsive specialist-list tracing-history-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Service</th>
                                        <th scope="col">Specialist</th>
                                        <th scope="col">Tracing Status Role</th>
                                        <th scope="col" class="hover_unset"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <slot v-if="this.logList.length > 0">
                                        <tr v-for="(item,index) in this.logList" :key="index">
                                            <td v-if="item.observation_datetime">{{ $filters.inputDateTime(item.observation_datetime, 'YYYY-MM-DD') }}</td>
                                            <td v-else>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD')}}</td>
                                            <td v-if="this.consultationsData && this.consultationsData.specialist_type">{{ this.consultationsData.specialist_type }}</td>
                                            <td v-else> {{ item.description }}</td>
                                            <td>{{ item.specialist_name }}</td>
                                            <td v-if="item.tracing_role=='Appointment Booked'">Booked</td>
                                            <td v-else>{{ item.tracing_role }}</td>
                                            <td><span class="popup_link" @click="viewLogDocument(item, item.tracing_role, item.patient_id, item.unique_id)"> {{ item.tracing_role === 'Created' ? 'View Request' : 'View File' }}</span></td>
                                        </tr>
                                        <!-- <tr v-if="this.logList && this.logList.length && this.logList[this.logList.length - 1].file_path">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <span class="popup_link" @click="viewPdf" v-if="this.logList[this.logList.length - 1].is_template_base"> View PDF</span>
                                                <span class="popup_link" @click="viewPdfFile" v-else> View PDF</span>
                                            </td>
                                        </tr> -->
                                    </slot>
                                    <slot v-else>
                                        <tr>
                                            
                                        </tr>
                                    </slot>
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
                                    <div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
                                        <selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="handleFaxInput" @keydown="handleKeyDown" @paste="handlePaste" :maxlength="this.maxLength">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                    <!-- <p class="separate-multiple-fax text-center mb-0">(use comma to separate multiple fax recipients. ex: 647777777, 647123123)</p> -->
                                </div>
                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Recipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm
                                <img src="images/loader.gif" v-if="faxLoader"  style="width: calc(var(--scale-ratio) * 18px);" />
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import sidebar from './sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import _ from 'lodash';

export default {
        setup: () => {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                specialist_text:"",
                isFaxFormVisible:true,
                isEmailFormVisible:false,
                isMailFormVisible:false,
                consultationsList:[],
                selectedItems:[],
                selectAllChk:false,
                allItems:[],
                sortBy:'',
                sortOrder:'',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    { value: "received_timerange", title: "Referral Date" },
                    { value: "patient_name", title: "Demographic" },
                    { value: "custom_specialists.specialist_type", title: "Service" },
                    { value: "custom_specialists.name", title: "Specialist" },
                    { value: "status", title: "Latest Status" },
                ],
                selectChk:[],
                logList: [],
                viewConsultationId: '',
				specialist_option: [],
				tagSpecialistLabel: "",
                consultationsData: "",
                faxLoader:false,
                searchTimeout: null,
            }
        },
        components: {
            sidebar,
            CustomFilter,
            ValidationErrorMessageList,
			selectCheckBox,
        },
        mounted(){
            this.resetFormData()
            document.addEventListener('click', this.handleClickOutside);
            this.getConsultationList()
            this.getSpecialistList()
        },
        methods:{
            handleInput() {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                // Start a new timer that triggers after 2 seconds of idle time
                this.searchTimeout = setTimeout(() => {
                    this.getConsultationList();
                }, 1000); // 1-second idle time
            },
            viewLogDocument(logDetail, tracingRole, patient_id,uniqueId){
                if (tracingRole === 'Created') {
                    if (logDetail.is_template_base) {
                        const url = this.$router.resolve({
                            name: 'editModule', params: { patient_id: patient_id, id:uniqueId, module:'consultation'}
                        }).href;
                        window.open(url, '_blank');
                    } else {                        
                        const url = this.$router.resolve({
                            name: 'PatientEChartConsultEdit', params: { patient_id: patient_id, id:uniqueId}
                        }).href;
                        window.open(url, '_blank');
                    }
                } else {
                    if (logDetail.is_template_base) {
                        window.open(this.documentUrl(logDetail.file_path), '_blank');
                    } else {
                        const url = this.$router.resolve({
                            name: 'PatientEChartFilesEdit', params: { id:logDetail.id,patient_id:patient_id}
                        }).href;
    
                        window.open(url, '_blank');

                    }
                }
            },
            viewPdf(){
                window.open(this.documentUrl(this.logList[this.logList.length - 1].file_path), '_blank');
            },
            viewPdfFile(){
                window.open(this.logList[this.logList.length - 1].file_path, '_blank');
            },
            closeViewLogs(){
                this.logList = [];
                this.viewConsultationId = '';
                const queryId = this.$route.query.id;
                if (queryId && Number(queryId) > 0) {
                    this.$router.push({ name: 'PatientEChartConsultList', params: {patient_id: this.$route.params.patient_id} });
                }
            },
            viewlogs(consultationId){
                this.viewConsultationId = consultationId;
                window.$("#add-viewlogs-model").modal("show");
                this.$store.state.loader = true;
                axios.post("patient/consultations/view-logs", { id: this.viewConsultationId })
                .then((response) => {
                    this.logList = response.data.data;

                    this.$store.state.loader = false;
                });
                this.consultationsData = this.consultationsList.find(element => element.id == consultationId);
            }, 
            exportconsults(){
                this.v$['faxForm'].$reset();
                this.v$['emailForm'].$reset();
                this.v$['mailForm'].$reset();
                Object.assign(
                    this.$store.state.consultations.faxForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.consultations.emailForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.consultations.mailForm,
                    this.$store.state.consultations.defaultexportfrm
                );
                this.tagSpecialistLabel = ''
                this.specialist_option.map((item) => {
					item.checked = false;
				});
                this.isFaxFormVisible=true
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                window.$("#add-exportconsultation-model").modal("show");
            },
            changeExportOpt(type){
                this.isFaxFormVisible=false
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                if (type == 'fax') {
                    this.isFaxFormVisible=true
                } else if (type == 'email') {
                    this.isEmailFormVisible=true
                } else if (type == 'mail') {
                    this.isMailFormVisible=true
                }
            },
            getConsultationList(){
                clearTimeout(this.searchTimeout);
                this.$store.state.loader = true;
                axios.post("patient/consultations/list", {
					patient_id: (this.$route.params.patient_id) ? this.$route.params.patient_id : null,
					keyword: this.form.keyword,
					advanceFilter:this.form.advanceFilter,
					sort_by: this.sortBy,
					sort_order:this.sortOrder
                })
                .then((response) => {
                    this.selectAllChk = false
                    this.selectedItems = []
                    this.allItems = []
                    this.consultationsList = response.data.data;
                    this.consultationsList.forEach(item => {
                        this.allItems.push(item.id)
                    });

                    this.$store.state.loader = false;
                }).catch(error => {
                        this.allItems = []
                        this.$store.state.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            // console.log(Object.values(error.response.data.data).join(', '))
                        } else if(error.response.status === 402){
                            window.location.href = error.response.data.redirect_url;
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                // Handle individual item selection/deselection
                const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                if (index !== -1) {
                    this.selectedItems.splice(index, 1); // Remove id from selectedItems
                } else {
                    this.selectedItems.push(id)
                }

                // Update selectAllChk based on selectedItems length
                this.selectAllChk = this.selectedItems.length === this.allItems.length;
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getConsultationList()
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    let value = item.value
                    if (item.value == 'Not Set') {
                        value = ''
                    }
                    let fieldName = item.field
                    if (fieldName == 'status'){
                        fieldName = 'consultations.status'
                    }
                    advanceFilter[fieldName] = value
                });
                this.form.advanceFilter = advanceFilter
            },
			selectedSpecialist(value){
                this.specialist_option = value;
				const specialist_list = this.specialist_option.map((item) => {
					return (item.checked == true) ? item.id : null;
				});

                this.faxForm.specialist_id = specialist_list.filter(n => n);
				if (this.faxForm.specialist_id.length > 0) {
                    if (this.faxForm.specialist_id.length == 1) {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
                    } else {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
                    }
                } else {
					this.tagSpecialistLabel = "";
				}
			},
            getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
							details.sort((a, b) => {
								return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
							});

                            const specialistTempData = details.map((item) => {
								let name = item.name;
								if (item.is_preferred_specialist) {
									name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
								}
                                if (item.address){
                                    name += "<p class='address-line mb-0'>"+item.address+"</p>"
                                }
								return { id: item.id, name: item.name, checked: false, displayLabel: name };
							});
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            handleItemSelected(values){
                this.faxForm.specialist_id = values.item.id
            },
            closeExportModel(){
                this.selectedItems = []
                this.selectAllChk = false
                const exportId = this.$route.query.exportId;
                if (exportId && Number(exportId) > 0) {
                    this.$router.push({ name: 'PatientEChartConsultList', params: {patient_id: this.$route.params.patient_id} });
                }
                window.$("#add-exportconsultation-model").modal("hide");
            },
            exportSubmit(){
                let form = null
                let formName = 'faxForm'
                if (this.isFaxFormVisible) {
                    form = { ...this.faxForm };
                    form.type = "fax"
                    formName = 'faxForm';
                    form.fax_number = this.faxForm['phoneNumbers'].join(', ');
                } else if(this.isEmailFormVisible){
                    form = this.emailForm
                    form.type = "email"
                    formName = 'emailForm';
                } else if (this.isMailFormVisible){
                    form = this.mailForm
                    form.type = "mail"
                    formName = 'mailForm';
                }
                form.ids = this.selectedItems
                form.patient_id = (this.$route.params.patient_id) ? this.$route.params.patient_id : null;
                this.v$[formName].$validate();

                if (!this.v$[formName].$error) {
                    this.faxLoader = true
                    this.faxForm.fax_number = '';

                    let url = "patient/consultations/export"
                        axios.post(url, form)
                            .then((response) => {
                                this.faxForm.fax_number = null
                                this.faxLoader = false
                                if (response.status == 200) {

                                    if (formName == 'faxForm') {
                                        if (response.data.data && response.data.data.redirectUrl) {
                                            window.open(response.data.data.redirectUrl, "_blank");
                                            return true
                                        }

                                        let allSuccess = true
                                        for (const detail of response.data.data) {
                                            if (detail.status == 'success'){
                                                this.$filters.moshaToast(detail.msg, "success");
                                            } else {
                                                allSuccess = false
                                                this.$filters.moshaToast(detail.msg, "error");
                                            }
                                        }
                                        if (allSuccess) {
                                            this.selectedItems = []
                                            this.selectAllChk = false
                                            this.faxForm.phoneNumbers = [];
                                            window.$("#add-exportconsultation-model").modal("hide");
                                        }
                                        const exportId = this.$route.query.exportId;
                                        if (exportId && Number(exportId) > 0) {
                                            this.$router.push({ name: 'PatientEChartConsultList', params: {patient_id: this.$route.params.patient_id} });
                                        }
                                    } else {
                                        this.selectedItems = []
                                        this.selectAllChk = false
                                        this.$filters.moshaToast(response.data.message, "success");
                                        window.$("#add-exportconsultation-model").modal("hide");
                                    }

                                } else {
                                    this.$filters.moshaToast(response.data.message, "error");
                                }
                            }).catch(error => {
                                this.faxLoader = false
                                this.faxForm.fax_number = null
                                this.$filters.moshaToast(error.response.data.message, "error");
                            });
                } else {
                    this.faxLoader = false
                    this.faxForm.fax_number = null
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }

            },
            documentUrl(documentPath) {
                // Assuming your documents are stored in the 'public' directory
                return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
            },
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getConsultationList()
                    this.isShowCustomFilter = false;
                }

            },
            handleFaxInput() {
                if (this.faxForm.phoneNumbers.length >= 5) {
                    // If the limit is exceeded, use the debounced version
                    this.debounceFormatFaxNumber();
                } else {
                    // Otherwise, call the method directly
                    this.formatFaxNumber();
                }
            },
            handlePaste(event) {
                // Get the pasted content
                const pasteData = (event.clipboardData || window.clipboardData).getData("text");
                // Allow only digits and "-"
                let sanitizedData = pasteData.replace(/[^0-9-]/g, "");

                // If the sanitized data is empty, reject the paste
                if (!sanitizedData) {
                    console.log("Invalid input: Only numbers and '-' are allowed.");
                    event.preventDefault();
                    return;
                }

                // Remove "-" from the sanitized data
                sanitizedData = sanitizedData.replace(/-/g, "");

                // Determine max length based on presence of "-"
                const currentLength = this.faxForm.fax_number ? this.faxForm.fax_number.length : 0;
                const allowedLength = this.maxLength - currentLength;
                // Trim pasted content to fit the remaining allowed length
                const trimmedData = sanitizedData.slice(0, allowedLength);

                // Update the input value
                this.faxForm.fax_number = (this.faxForm.fax_number || "") + trimmedData;
            },
            handleKeyDown(event) {
                if (event.key == 'Backspace' && this.faxForm.fax_number) {
                    this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                    event.preventDefault();
                }
                if (this.faxForm.fax_number && this.faxForm.fax_number.length >= this.maxLength){
                    event.preventDefault();
                }

                const allowedKeys = [
                    "Backspace",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab",
                    "-",
                ];
                const isAllowedKey = allowedKeys.includes(event.key);
                const isDigit = event.key >= "0" && event.key <= "9";
                const isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "v";
                if (!isDigit && !isAllowedKey && !isPasteShortcut) {
                    event.preventDefault();
                }

            },
            formatFaxNumber() {
                const { fax_number, phoneNumbers } = this.faxForm;

                if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
                    this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                    this.faxForm.fax_number = '';
                    return;
                }

                // Ensure no trailing commas and trim input
                if (fax_number.endsWith(',')) {
                    this.faxForm.fax_number = fax_number.slice(0, -1).trim();
                    return;
                }

                // Format the fax numbers
                const result = this.formatFaxWithCheckDuplicateNumber(fax_number, phoneNumbers);

                // Show the toast message, if try to add duplicate fax number
                if (result.hasDuplicates) {
                    this.$filters.moshaToast("You cannot add recipient duplicate fax numbers.", "error");
                }

                // Update the model
                this.faxForm.fax_number = result.faxNumber;
                this.faxForm.phoneNumbers = result.phoneNumbers;
            },
            formatFaxWithCheckDuplicateNumber(faxNumber, phoneNumbers) {
                let faxRegex = /^(1[ .-]?)?(\d{3}|\(\d{3}\))[ .-]?\d{3}[ .-]?\d{4}$/; // Pattern for fax numbers
                faxNumber = faxNumber.trim(); // Trim spaces

                let faxNumbersArray = faxNumber.split(',').map(fax => fax.trim()); // Split input by comma

                // Check for invalid numbers if the last character is a comma
                if (faxNumber.endsWith(',')) {
                    const lastFaxNumber = faxNumbersArray[faxNumbersArray.length - 2];
                    if (lastFaxNumber && lastFaxNumber.length === 0 || !faxRegex.test(lastFaxNumber)) {
                        faxNumbersArray.splice(faxNumbersArray.length - 2, 1); // Remove invalid number
                        faxNumber = faxNumbersArray.join(', ');
                        return { faxNumber, phoneNumbers };
                    }
                }

                // Format and validate fax numbers
                const formattedFaxNumbers = faxNumbersArray.map(number => {
                    const numericFax = number.replace(/\D/g, ""); // Remove non-numeric characters
                    if (numericFax.length >= 3 && numericFax.length <= 6) {
                        return `${numericFax.slice(0, 3)}-${numericFax.slice(3)}`;
                    } else if (numericFax.length > 6) {
                        return `${numericFax.slice(0, 3)}-${numericFax.slice(3, 6)}-${numericFax.slice(6, 10)}`;
                    }
                    return number; // Keep it as is if length < 3
                });

                let remainingFaxNumbers = [];
                let hasDuplicates = false;

                // Check for duplicates and validate numbers
                formattedFaxNumbers.forEach(formattedFax => {
                    if (faxRegex.test(formattedFax)) {
                        if (phoneNumbers.includes(formattedFax)) {
                            hasDuplicates = true; // Detect duplicates
                        } else {
                            phoneNumbers.push(formattedFax); // Add valid number
                        }
                    } else {
                        remainingFaxNumbers.push(formattedFax); // Store invalid numbers
                    }
                });

                // Remove duplicates and sort numbers
                phoneNumbers = Array.from(new Set(phoneNumbers)).sort((a, b) => {
                    const numA = a.replace(/\D/g, '');
                    const numB = b.replace(/\D/g, '');
                    return numB.localeCompare(numA);
                });

                // Join remaining invalid numbers back with commas
                faxNumber = remainingFaxNumbers.join(', ');

                return { faxNumber, phoneNumbers, hasDuplicates };
            },
            removeSelectedFax(numberToRemove){
                const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
                this.faxForm.phoneNumbers = updatedPhoneNumbers
            },
            isUserDocORNurse() {
                let userRole = localStorage.getItem('authUserRole');
                if(userRole == 'Doctor' || userRole == 'Nurse') {
                    return true;
                }
                return false;
            },
            resetFormData() {
                Object.assign(
                    this.$store.state.consultations.form,
                    this.$store.state.consultations.defaultFormData,
                );
            },
        },
        created(){
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                setTimeout(() => {
                    this.viewlogs(Number(queryId))
                }, 1000);
            }

            const exportId = this.$route.query.exportId;
            if (exportId && Number(exportId) > 0) {
                this.selectedItems = [Number(exportId)]
                setTimeout(() => {
                    this.exportconsults()
                }, 1000);
            }

            this.debounceFormatFaxNumber = _.debounce(this.formatFaxNumber, 300); // Wait 300ms after typing stops
        },
        computed: {
            form() {
                return this.$store.state.consultations.form;
            },
            faxForm(){
                return this.$store.state.consultations.faxForm;
            },
            emailForm(){
                return this.$store.state.consultations.emailForm;
            },
            mailForm(){
                return this.$store.state.consultations.mailForm;
            },
            maxLength() {
                return (this.faxForm.fax_number && this.faxForm.fax_number.includes("-")) ? 12 : 10;
            },
        },
        validations() {
            return {
                faxForm: this.$store.state.consultations.validationRules.faxForm,
                emailForm: this.$store.state.consultations.validationRules.emailForm,
                mailForm: this.$store.state.consultations.validationRules.mailForm,
            };

        },
}
</script>
